import { useEffect, useState } from "react";
import parse from "html-react-parser";
import IconQuote from "../../assets/icons/quote.png";
// import { ReactComponent as IconQuote } from "../../assets/icons/quote.svg";
import ArrowsExpand from "../../assets/arrows-expand.png";
// import { ReactComponent as ArrowsExpand } from "../../assets/arrows-expand.svg";
import { Scrollbars } from "react-custom-scrollbars-2";
import ArrowsCollapse from "../../assets/arrows-collapse.png";
import { useGA4React } from "ga-4-react";
// import { ReactComponent as ArrowsCollapse } from "../../assets/arrows-collapse.svg";

export function CardQuote({ cardData }) {
	const strippedHTML = (htmlString) => {
		return htmlString.replace(/<[^>]+>/g, "");
	};

	return (
		<>
			<div className="c-card__quote-wrap">
				<div className="c-card__quote-name">
					<div>
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
					<div data-why="for-hover">
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
				</div>
				<div className="c-card__quote-content">{parse(strippedHTML(cardData.content))}</div>
				<div className="c-card__quote-shader"></div>
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconQuote} width={22} height={22} alt="" />
			</div>
			<div className="c-card__quote-controls">
				<button>
					<img src={ArrowsExpand} width={20} height={20} />
				</button>
			</div>
		</>
	);
}

export function CardQuoteActive({ cardData }) {
	const [shouldShowScrollbars, setShouldShowScrollbars] = useState(false);
	const ga4React = useGA4React();

	useEffect(() => {
		setTimeout(() => {
			setShouldShowScrollbars(true);
		}, 1500);
	}, []);

	return (
		<>
			<div className="c-card__quote-wrap" style={{ width: "100%" }}>
				<div className="c-card__quote-name">
					<div>
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
					<div data-why="for-hover">
						{cardData.firstname}
						<br />
						{cardData.lastname}
					</div>
				</div>

				<div
					className={`c-card__quote-content ${
						cardData.year ? "c-card__quote-content--flexed" : "c-card__quote-content--unflexed"
					}`}
				>
					{cardData.year && <div className="c-card__quote-year">{cardData.year}</div>}
					<div>
						{shouldShowScrollbars && (
							<Scrollbars autoHeightMin={100} autoHeightMax={500}>
								{parse(cardData.content)}
							</Scrollbars>
						)}
						{!shouldShowScrollbars && parse(cardData.content)}
					</div>
				</div>
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconQuote} width={22} height={22} alt="" />
			</div>
			<div className="c-card__quote-controls">
				<button>
					<img src={ArrowsCollapse} width={20} height={20} alt="" />
				</button>
			</div>
		</>
	);
}
