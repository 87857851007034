import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import IconPhoto from "../../assets/icons/photo.png";
// import { ReactComponent as IconPhoto } from "../../assets/icons/photo.svg";
import parse from "html-react-parser";
import ImageWithPreloader from "../image-with-preloader/ImageWithPreloader";
import React from "react";

export function CardPhoto({ cardData }) {
	return (
		<>
			<div className="c-card__photo-bg">
				<ImageWithPreloader src={cardData.thumb_url} />
			</div>
			<div className="c-card__hover-title">
				<div>
					{cardData.firstname}
					<br />
					{cardData.lastname}
				</div>
				<ArrowRight />
			</div>
			<div className={`c-card__icon-wrap c-card__icon-wrap-${cardData.type}`}>
				<img src={IconPhoto} width={24} height={20} />
			</div>
		</>
	);
}

export function CardPhotoActive({ cardData }) {
	return (
		<div className="content-holder">
			{/*{cardData.year && <div className="c-card__photo-year">{cardData.year}</div>}*/}
			<div className="c-card__photo-img">
				<img width={cardData.imgw} height={cardData.imgh} src={cardData.url} className="c-card__photo-img-bg" alt="" />
				{/*<img width={cardData.imgw} height={cardData.imgh} src={cardData.url} />*/}
				<ImageWithPreloader width={cardData.imgw} height={cardData.imgh} src={cardData.url} />
			</div>
			<div className="c-card__photo-content">
				<div className="c-card__photo-content-name">
					{cardData.firstname} {cardData.lastname}
				</div>
				<div className="c-card__photo-content-desc">{parse(cardData.desc)}</div>
			</div>
		</div>
	);
}
